export const hydrate = (model, data) => {
  for (const key of Object.keys(model)) {
    if (undefined !== data[key]) {
      if (model[key] === null) {
        model[key] = data[key];
      } else if (typeof model[key] === 'object') {
        if (Array.isArray(model[key])) {
          model[key] = data[key];
        } else {
          hydrate(model[key], data[key]);
        }
      } else {
        model[key] = data[key];
      }
    }
  }
};

export const findInArrayById = (id, array) => {
  const exist = false;
  for (const i in array) {
    if (parseInt(array[i].id) === parseInt(id)) {
      return i;
    }
  }
  return exist;
};

export const refine = (model) => {
  for (const key of Object.keys(model)) {
    if (undefined !== model[key] && Array.isArray(model[key])) {
     const arrTmp = model[key];
     model[key] = [];
     for (const k of arrTmp) {
       if (undefined !== k.id) {
         model[key].push(k.id);
       }
     }
    }
  }
};
