<template>
  <v-col
    class="flex-grow-1"
  >
    <v-overlay
      absolute
      :value="loading"
    >
      <o-loader />
    </v-overlay>
    <o-form
      ref="form"
      @submit="onSubmit"
    >
      <v-row no-gutters>
        <v-col class="col-lg-6">
          <o-select
            v-model="form.category"
            :rules="validation.category"
            label="labels.item.category"
            prepend-icon="mdi-group"
            :items="categories"
            item-text="name"
            item-value="id"
          >
            <template v-slot:item="{ item }">
              <v-icon
                :color="`#${item.color}`"
                class="mr-2"
              >
                {{ 'mdi-' + item.icon }}
              </v-icon>
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-icon
                :color="`#${item.color}`"
                class="mr-2"
              >
                {{ 'mdi-' + item.icon }}
              </v-icon>
              {{ item.name }}
            </template>
          </o-select>
        </v-col>
        <v-col class="col-md-6">
          <v-switch
            v-model="form.visible"
            class="float-right v-input--reverse"
            color="secondary"
            :label="$t('labels.item.visible')"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <o-text-field
            v-model="form.name"
            :rules="validation.name"
            label="labels.item.name"
            prepend-icon="mdi-rename-box"
          />
          <o-textarea
            v-model="form.description"
            :rules="validation.description"
            label="labels.item.description"
            prepend-icon="mdi-text"
            filled
            auto-grow
            rows="2"
          />

          <v-divider class="mx-4" />

          <v-switch
            v-model="customizeIconAndColor"
            color="secondary"
            :label="$t('messages.views.admin.components.information.window.itemForm.customizeIconAndColor')"
          />

          <v-expand-transition
            mode="out-in"
          >
            <div v-show="customizeIconAndColor">
              <o-icon-field
                ref="iconField"
                v-model="form.icon"
                :rules="validation.icon"
                label="labels.item.icon"
              />
              <o-color-field
                v-model="form.color"
                :rules="validation.color"
                label="labels.item.color"
              />
            </div>
          </v-expand-transition>

          <v-divider class="mx-4" />

          <v-switch
            v-model="customizeDates"
            color="secondary"
            :label="$t('messages.views.admin.components.information.window.itemForm.customizeDates')"
          />

          <v-expand-transition
            mode="out-in"
          >
            <v-row
              v-show="customizeDates"
              no-gutters
            >
              <v-col>
                <o-date-time-field
                  v-model="form.startingAt"
                  :rules="{
                    ...validation.startingAt,
                    before: {
                      target: form.endingAt,
                      strict: true,
                      withTime: true,
                    },
                  }"
                  label="labels.item.startingAt"
                  prepend-icon="mdi-calendar-start"
                />
              </v-col>
              <v-col>
                <o-date-time-field
                  v-model="form.endingAt"
                  :rules="{
                    ...validation.endingAt,
                    after: {
                      target: form.startingAt,
                      strict: true,
                      withTime: true,
                    },
                  }"
                  label="labels.item.endingAt"
                  prepend-icon="mdi-calendar-end"
                />
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-divider class="mx-4" />

          <v-tabs
            v-model="shape"
            fixed-tabs
          >
            <v-tab
              v-for="(items, type) of form.shapes"
              :key="`shapes-${type}`"
              :href="`#${type}`"
            >
              <v-icon
                left
                :color="'#' + finalColor"
                v-text="icons[type]"
              />
              <span>
                {{ $t('messages.views.admin.components.information.window.itemForm.shapes.' + type) }}
              </span>
            </v-tab>

            <v-tabs-items
              v-model="shape"
              class="fill-height"
            >
              <v-card
                flat
                class="fill-height"
              >
                <v-card-text>
                  <v-tab-item value="itemMarker">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th />
                            <th class="text-center">
                              {{ $t('messages.views.admin.components.information.window.itemForm.latitude') }}
                            </th>
                            <th class="text-center">
                              {{ $t('messages.views.admin.components.information.window.itemForm.longitude') }}
                            </th>
                            <th class="text-right">
                              <v-btn
                                fab
                                x-small
                                color="secondary"
                                @click="() => $store.commit('map/changeMode', { mode: 'create_item_marker' })"
                              >
                                <v-icon small>
                                  mdi-map-marker-plus
                                </v-icon>
                              </v-btn>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="!Object.keys(form.shapes.itemMarker).length">
                            <td
                              colspan="4"
                              class="font-italic text-caption text-center"
                            >
                              {{ $t('messages.views.admin.components.information.window.itemForm.noMarker') }}
                            </td>
                          </tr>
                          <tr
                            v-for="(marker, markerId, i) in form.shapes.itemMarker"
                            :key="`marker-${i}`"
                          >
                            <td class="text-center">
                              {{ '#' + i }}
                            </td>
                            <td class="text-center">
                              <o-text-field
                                v-model.number="marker.location.lat"
                                dense
                                hide-details
                              />
                            </td>
                            <td class="text-center">
                              <o-text-field
                                v-model.number="marker.location.lng"
                                dense
                                hide-details
                              />
                            </td>
                            <td class="text-right">
                              <v-btn
                                icon
                                @click="() => $store.commit('map/flyTo', {
                                  location: marker.location,
                                  zoom: 16
                                })"
                              >
                                <v-icon small>
                                  mdi-target
                                </v-icon>
                              </v-btn>
                              <v-btn
                                v-confirm="() => deleteShape({
                                  type: 'itemMarker',
                                  id: markerId,
                                })"
                                confirm-title="messages.views.admin.components.information.window.itemForm.deleteMarker.title"
                                confirm-text="messages.views.admin.components.information.window.itemForm.deleteMarker.text"
                                icon
                              >
                                <v-icon small>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>

                  <v-tab-item value="itemCircle">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th />
                            <th class="text-center">
                              {{ $t('messages.views.admin.components.information.window.itemForm.latitude') }}
                            </th>
                            <th class="text-center">
                              {{ $t('messages.views.admin.components.information.window.itemForm.longitude') }}
                            </th>
                            <th class="text-center">
                              {{ $t('messages.views.admin.components.information.window.itemForm.radius') }}
                            </th>
                            <th class="text-right">
                              <v-btn
                                fab
                                x-small
                                color="secondary"
                                @click="() => $store.commit('map/changeMode', { mode: 'create_item_circle' })"
                              >
                                <v-icon small>
                                  mdi-shape-circle-plus
                                </v-icon>
                              </v-btn>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="!Object.keys(form.shapes.itemCircle).length">
                            <td
                              colspan="4"
                              class="font-italic text-caption text-center"
                            >
                              {{ $t('messages.views.admin.components.information.window.itemForm.noCircle') }}
                            </td>
                          </tr>
                          <tr
                            v-for="(circle, circleId, i) in form.shapes.itemCircle"
                            :key="`circle-${i}`"
                          >
                            <td class="text-center">
                              {{ '#' + i }}
                            </td>
                            <td class="text-center">
                              <o-text-field
                                v-model.number="circle.center.lat"
                                dense
                                hide-details
                              />
                            </td>
                            <td class="text-center">
                              <o-text-field
                                v-model.number="circle.center.lng"
                                dense
                                hide-details
                              />
                            </td>
                            <td class="text-center">
                              <o-text-field
                                v-model.number="circle.radius"
                                dense
                                hide-details
                              />
                            </td>
                            <td class="text-right flex-shrink-0 flex-grow-1">
                              <v-btn
                                icon
                                @click="() => $store.commit('map/flyTo', {
                                  location: getCircleBoundaries(circle),
                                })"
                              >
                                <v-icon small>
                                  mdi-target
                                </v-icon>
                              </v-btn>
                              <v-btn
                                v-confirm="() => deleteShape({
                                  type: 'itemCircle',
                                  id: circleId,
                                })"
                                confirm-title="messages.views.admin.components.information.window.itemForm.deleteCircle.title"
                                confirm-text="messages.views.admin.components.information.window.itemForm.deleteCircle.text"
                                icon
                              >
                                <v-icon small>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>

                  <v-tab-item value="itemPolygon">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th />
                            <th class="text-center">
                              {{ $t('messages.views.admin.components.information.window.itemForm.area') }}
                            </th>
                            <th class="text-right">
                              <v-btn
                                fab
                                x-small
                                color="secondary"
                                @click="() => $store.commit('map/changeMode', { mode: 'create_item_polygon' })"
                              >
                                <v-icon small>
                                  mdi-shape-polygon-plus
                                </v-icon>
                              </v-btn>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="!Object.keys(form.shapes.itemPolygon).length">
                            <td
                              colspan="4"
                              class="font-italic text-caption text-center"
                            >
                              {{ $t('messages.views.admin.components.information.window.itemForm.noPolygon') }}
                            </td>
                          </tr>
                          <tr
                            v-for="(polygon, polygonId, i) in form.shapes.itemPolygon"
                            :key="`polygon-${i}`"
                          >
                            <td class="text-center">
                              {{ '#' + i }}
                            </td>
                            <td class="text-center">
                              {{ getPolygonArea(polygon) }}
                            </td>
                            <td class="text-right">
                              <v-btn
                                icon
                                @click="() => $store.commit('map/flyTo', { location: polygon.points })"
                              >
                                <v-icon small>
                                  mdi-target
                                </v-icon>
                              </v-btn>
                              <v-btn
                                v-confirm="() => deleteShape({
                                  type: 'itemPolygon',
                                  id: polygonId,
                                })"
                                confirm-title="messages.views.admin.components.information.window.itemForm.deletePolygon.title"
                                confirm-text="messages.views.admin.components.information.window.itemForm.deletePolygon.text"
                                icon
                              >
                                <v-icon small>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-card-text>
              </v-card>
            </v-tabs-items>
          </v-tabs>

          <o-submit />
        </v-col>
      </v-row>
    </o-form>
  </v-col>
</template>

<script>
  import area from '@turf/area';
  import { hydrate } from '@utils/form';
  import { cloneDeep } from 'lodash';
  import Vue from 'vue';
  import moment from 'moment';
  import { getCircleBoundaries } from '@/utils/geo';
  import ActionControl from '@/map/controls/ActionControl';
  import ItemPolygonConverter from '@/map/converters/ItemPolygonConverter';

  export default {
    props: {
      id: {
        type: [String, Number],
        default: null,
      },
    },

    apollo: {
      categories: {
        query: require('@gql/views/admin/components/information/window/itemForm/getCategories.gql'),
        client: 'information',
        fetchPolicy: 'no-cache',
        update (data) {
          this.ready = true;

          return data.getCategories;
        },
      },
    },

    data: function () {
      return {
        ready: false,
        mode: 'create',
        loading: false,
        customizeIconAndColor: false,
        customizeDates: false,
        form: {
          category: null,
          name: '',
          description: '',
          startingAt: null,
          endingAt: null,
          color: null,
          icon: null,
          shapes: {
            itemMarker: {},
            itemCircle: {},
            itemPolygon: {},
          },
          visible: true,
        },
        validation: require('@/validation/entities/item.json'),
        objectId: 1,
        shape: 'itemMarker',
        changed: null,
        preventChanges: false,
        selected: {
          type: null,
          id: null,
          feature: null,
        },
      };
    },

    computed: {
      finalIcon () {
        if (this.form.icon && this.$refs.iconField.isIconValid) {
          return this.form.icon;
        } else if (this.form.category !== null) {
          const category = this.categories.find((category) => category.id === this.form.category);

          if (category) {
            return category.icon;
          }
        }

        return 'map-marker';
      },
      finalColor () {
        if (this.form.color) {
          return this.form.color;
        } else if (this.form.category !== null) {
          const category = this.categories.find((category) => category.id === this.form.category);

          if (category) {
            return category.color;
          }
        }

        return '0000FF';
      },
      icons () {
        return {
          markers: 'mdi-' + this.finalIcon,
          circles: 'mdi-checkbox-blank-circle-outline',
          polygons: 'mdi-vector-polygon',
        };
      },
    },

    watch: {
      customizeIconAndColor (value) {
        if (!value) {
          this.form.icon = null;
          this.form.color = null;
        }
      },
      customizeDates (value) {
        if (!value) {
          this.form.startingAt = null;
          this.form.endingAt = null;
        }
      },
      form: {
        deep: true,
        handler (value) {
          this.$nextTick(this.updateMapFeatures);
        },
      },
      ready (value) {
        if (value && this.mode === 'update') {
          this.loadItem();
        }
      },
      'selected.type' (value) {
        this.$nextTick(this.updateMapActions);
      },
    },

    created () {
      if (this.id) {
        this.loading = true;
        this.mode = 'update';
      }

      this.$bus.$on('map.feature.selected', this.onFeatureSelected);
      this.$bus.$on('map.feature.unselected', this.onFeatureUnselected);
      this.$bus.$on('map.feature.created', this.createShape);
      this.$bus.$on('map.feature.updated', this.updateShape);
      this.$bus.$on('map.feature.deleted', this.deleteShape);
    },

    destroyed () {
      this.$store.commit('map/removeAllFeatures', {});

      this.$bus.$off('map.feature.selected', this.onFeatureSelected);
      this.$bus.$off('map.feature.unselected', this.onFeatureUnselected);
      this.$bus.$off('map.feature.created', this.createShape);
      this.$bus.$off('map.feature.updated', this.updateShape);
      this.$bus.$off('map.feature.deleted', this.deleteShape);
    },

    methods: {
      onSubmit () {
        this.loading = true;

        const variables = {
          input: cloneDeep(this.form),
        };

        variables.input.hide = !variables.input.visible;
        delete variables.input.visible;

        variables.input.description = variables.input.description && variables.input.description.length
          ? variables.input.description
          : null
        ;
        variables.input.icon = variables.input.icon
          ? variables.input.icon
          : null
        ;
        variables.input.color = variables.input.color
          ? variables.input.color
          : null
        ;
        variables.input.startingAt = variables.input.startingAt
          ? moment(variables.input.startingAt).utc().format('Y-MM-DD HH:mm:ss')
          : null
        ;
        variables.input.endingAt = variables.input.endingAt
          ? moment(variables.input.endingAt).utc().format('Y-MM-DD HH:mm:ss')
          : null
        ;

        const markers = [];
        const circles = [];
        const polygons = [];

        for (const id of Object.keys(variables.input.shapes.itemMarker)) {
          const marker = variables.input.shapes.itemMarker[id];
          markers.push(marker.location);
        }

        for (const id of Object.keys(variables.input.shapes.itemCircle)) {
          const circle = variables.input.shapes.itemCircle[id];
          circles.push({
            radius: circle.radius,
            center: circle.center,
          });
        }

        for (const id of Object.keys(variables.input.shapes.itemPolygon)) {
          const polygon = variables.input.shapes.itemPolygon[id];
          polygons.push({
            points: polygon.points,
          });
        }

        variables.input.shapes = {
          markers: markers,
          circles: circles,
          polygons: polygons,
        };

        let mutation;
        switch (this.mode) {
          case 'create':
            mutation = require('@gql/mutations/item/createItem.gql');
            break;
          case 'update':
            mutation = require('@gql/mutations/item/updateItem.gql');
            variables.input.id = this.id;
            break;
        }

        this.$apollo.mutate({
          mutation: mutation,
          client: 'information',
          variables: variables,
        }).then((result) => {
          this.$emit('submit', result[this.mode + 'Item']);
          this.$flash('messages.views.admin.components.information.window.itemForm.' + this.mode + '.success', 'success');
        }).catch((e) => {
          this.$flash('messages.views.admin.components.information.window.itemForm.' + this.mode + '.error', 'error');
        }).finally(() => {
          this.loading = false;
        });
      },

      onFeatureSelected ({ type, id, feature }) {
        this.selected.type = type;
        this.selected.id = id;
        this.selected.feature = feature;
      },

      onFeatureUnselected () {
        this.selected.type = null;
        this.selected.id = null;
        this.selected.feature = null;
      },

      createShape ({ id, type, feature, preventChange }) {
        id = id !== undefined ? id : '_' + this.objectId++;
        if (preventChange === undefined || !preventChange) {
          this.changed = {
            action: 'create',
            type: type,
            id: id,
            feature: feature,
          };
        }
        Vue.set(this.form.shapes[type], id, feature);
      },

      updateShape ({ type, id, feature }) {
        this.changed = {
          action: 'update',
          type: type,
          id: id,
          feature: feature,
        };
        this.form.shapes[type][id] = feature;
      },

      deleteShape ({ type, id, feature }) {
        this.changed = {
          action: 'delete',
          type: type,
          id: id,
          feature: this.form.shapes[type],
        };
        Vue.delete(this.form.shapes[type], id);
      },

      updateMapFeatures () {
        if (!this.preventChanges) {
          this.$refs.form.onFormChanged();

          if (this.changed !== null) {
            switch (this.changed.action) {
              case 'create':
                this.$store.commit('map/addFeature', {
                  type: this.changed.type,
                  feature: this.shapeToMapFeature(this.changed.type, this.changed.id, this.changed.feature),
                  extraProperties: {
                    editable: true,
                    draggable: true,
                  },
                });
                break;
              case 'update':
                this.$store.commit('map/updateFeature', {
                  type: this.changed.type,
                  feature: this.shapeToMapFeature(this.changed.type, this.changed.id, this.changed.feature),
                  extraProperties: {
                    editable: true,
                    draggable: true,
                  },
                });
                break;
              case 'delete':
                this.$store.commit('map/removeFeature', {
                  type: this.changed.type,
                  feature: this.shapeToMapFeature(this.changed.type, this.changed.id, this.changed.feature),
                });
                break;
            }

            this.changed = null;
          } else {
            const features = {
              itemMarker: [],
              itemCircle: [],
              itemPolygon: [],
            };

            for (const type of Object.keys(this.form.shapes)) {
              for (const id of Object.keys(this.form.shapes[type])) {
                features[type].push(this.shapeToMapFeature(type, id, this.form.shapes[type][id]));
              }
            }

            this.$store.commit('map/setFeatures', {
              type: 'itemMarker',
              features: features.itemMarker,
              extraProperties: {
                editable: true,
                draggable: true,
              },
            });

            this.$store.commit('map/setFeatures', {
              type: 'itemCircle',
              features: features.itemCircle,
              extraProperties: {
                editable: true,
                draggable: true,
              },
            });

            this.$store.commit('map/setFeatures', {
              type: 'itemPolygon',
              features: features.itemPolygon,
              extraProperties: {
                editable: true,
                draggable: true,
              },
            });
          }
        }
      },

      updateMapActions () {
        let actions;

        if (this.selected.type !== null) {
          actions = [
            ActionControl.actions.DELETE,
            ActionControl.actions.CANCEL,
          ];
        } else {
          actions = [];
        }

        this.$store.commit('map/setDefaultActions', actions);
      },

      loadItem () {
        this.preventChanges = true;
        this.$apollo.query({
          query: require('@gql/views/admin/components/information/window/itemForm/getItem.gql'),
          client: 'information',
          fetchPolicy: 'no-cache',
          variables: {
            id: parseInt(this.id),
          },
        })
          .then((result) => {
            if (result.data.getItem.startingAt !== null) {
              result.data.getItem.startingAt = moment.utc(result.data.getItem.startingAt).toDate();
            }
            if (result.data.getItem.endingAt !== null) {
              result.data.getItem.endingAt = moment.utc(result.data.getItem.endingAt).toDate();
            }

            for (const itemMarker of result.data.getItem.shapes.markers) {
              this.createShape({
                type: 'itemMarker',
                feature: {
                  location: {
                    lat: itemMarker.lat,
                    lng: itemMarker.lng,
                  },
                },
                preventChange: true,
              });
            }

            for (const itemCircle of result.data.getItem.shapes.circles) {
              this.createShape({
                type: 'itemCircle',
                feature: itemCircle,
                preventChange: true,
              });
            }

            for (const itemPolygon of result.data.getItem.shapes.polygons) {
              this.createShape({
                type: 'itemPolygon',
                feature: itemPolygon,
                preventChange: true,
              });
            }

            hydrate(this.form, result.data.getItem);
            this.customizeIconAndColor = this.form.color || this.form.icon;
            this.customizeDates = this.form.startingAt || this.form.endingAt;
            this.loading = false;

            this.flyToItem();
          }).catch(() => {
            this.$flash(null, 'error');
          }).then(() => {
            this.preventChanges = false;
            this.updateMapFeatures();
          })
        ;
      },

      getPolygonArea (polygon) {
        return Math.round(area(ItemPolygonConverter.featureToGeoJson(polygon)));
      },

      getCircleBoundaries (circle) {
        return getCircleBoundaries(circle);
      },

      flyToItem () {
        const points = [];
        for (const id of Object.keys(this.form.shapes.itemMarker)) {
          points.push(this.form.shapes.itemMarker[id].location);
        }
        for (const id of Object.keys(this.form.shapes.itemCircle)) {
          points.push(...this.getCircleBoundaries(this.form.shapes.itemCircle[id]));
        }
        for (const id of Object.keys(this.form.shapes.itemPolygon)) {
          points.push(...this.form.shapes.itemPolygon[id].points);
        }

        if (points.length) {
          this.$store.commit('map/flyTo', { location: points });
        }
      },

      shapeToMapFeature (type, id, shape) {
        switch (type) {
          case 'itemMarker':
            return {
              id: id,
              location: shape.location,
              selected: !!shape.selected,
              icon: 'mdi-' + this.finalIcon,
              color: '#' + this.finalColor,
            };
          case 'itemCircle':
            return {
              id: id,
              center: shape.center,
              radius: shape.radius,
              selected: !!shape.selected,
              color: '#' + this.finalColor,
            };
          case 'itemPolygon':
            return {
              id: id,
              points: shape.points,
              selected: !!shape.selected,
              color: '#' + this.finalColor,
            };
        }
      },
    },
  };
</script>
